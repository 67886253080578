import React from "react";
import tw from "twin.macro";
import Carousel from "./carousel";
import AboutCpctnet from "./about-cpctnet";
import AboutDmacc from "./about-dmacc";
import Laos from "./laos";
import WhatDmaccDoes from "./what-dmacc-does";
import Map from "../contact/map";

const SideBySide = tw.div`mt-4 md:grid md:grid-cols-2 md:gap-8`;
const Container = tw.div`container mx-auto`;

const HomepageContent = () => (
  <>
    <Carousel />
    <Container>
      <SideBySide>
        <AboutCpctnet />
        <AboutDmacc />
      </SideBySide>
      <Map/>
      <WhatDmaccDoes />
      <Laos />
    </Container>
  </>
);

export default HomepageContent;

import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Img from "gatsby-image";
import {
  LayersControl,
  LayerGroup,
  MapContainer,
  TileLayer,
  Popup,
  Marker
} from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";
import "react-leaflet-markercluster/dist/styles.min.css";
import tw from "twin.macro";

import { Heading } from "../section";
import config from "../../../gatsby-config.js"

import "./map.css";
import uwdmaccSvg from "../team/uw-logo.svg";
import frontierSvg from "../team/frontier-logo.svg";
import badgeSvg from "../home/cp-ctnet-badge-color.svg";

const formatUrl = url => `${process.env.NODE_ENV == 'development' ? '' : config.pathPrefix}${url}`

const createClusterProposedIcon = function (cluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "marker-cluster-proposed",
    iconSize: L.point(40, 40, true)
  });
};

const createClusterAoIcon = function (cluster) {
  return L.divIcon({
    html: `<span>${cluster.getChildCount()}</span>`,
    className: "marker-cluster-ao",
    iconSize: L.point(40, 40, true)
  });
};

const logos = {
  frontier: frontierSvg,
  uwdmacc: uwdmaccSvg,
  nci: badgeSvg
};

const PopName = tw.h4`font-bold my-0 text-sm leading-tight`;
const PopOrg = tw.div`text-xs my-0`;
const PopLink = tw.a`text-blue-700 hocus:text-blue-900 text-xs block`;

const Map = () => {
  const laos = useStaticQuery(graphql`
    query {
      allAosJson {
        edges {
          node {
            id
            name
            city
            country
            coordinates {
              lat
              long
            }
            proposed
          }
        }
      }
      allLaosJson {
        edges {
          node {
            id
            type
            name
            org
            url
            image {
              childImageSharp {
                fixed(height: 60) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
            coordinates {
              lat
              long
            }
            pi {
              name
              degree
              role
            }
          }
        }
      }
    }
  `);

  return (
    <div tw="w-full">
      <Heading id="sites-map">Interactive Map of the Cancer Prevention Clinical Trials Network (CP-CTNET)</Heading>
      {typeof window !== "undefined" && (
        <MapContainer tw="h-96" center={[42, 0]} zoom={2} scrollWheelZoom={false} maxZoom={18} zoomDelta={1}>
          <LayersControl position="topright">
            <TileLayer
              attribution={`&copy; <a href="https://www.osm.org/copyright">OpenStreetMap</a> contributors`}
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            />
            <LayersControl.Overlay checked name="DMACC">
              <LayerGroup>
                {laos.allLaosJson.edges
                  .filter(edge => edge.node.type == "dmacc")
                  .map(edge => (
                    <Marker
                      icon={
                        new L.Icon({
                          iconUrl: formatUrl("/marker-icon-green.png"),
                          shadowUrl: formatUrl("/marker-shadow.png"),
                          iconSize: [25, 41],
                          iconAnchor: [12, 41],
                          popupAnchor: [1, -34],
                          shadowSize: [41, 41]
                        })
                      }
                      key={edge.node.id}
                      position={[
                        edge.node.coordinates.lat,
                        edge.node.coordinates.long
                      ]}
                    >
                      <Popup>
                        {laos[edge.node.id] && (
                          <Img
                            fixed={laos[edge.node.id]?.childImageSharp?.fixed}
                          />
                        )}
                        {logos[edge.node.id] && (
                          <img
                            src={logos[edge.node.id]}
                            alt={`${edge.node.name} logo`}
                          />
                        )}
                        <PopName>{edge.node.name}</PopName>
                        {edge.node.pi?.map(pi => (
                          <div key={pi.name} tw="my-2">
                            <PopOrg>
                              {pi.name},{pi.degree && ` ${pi.degree},`}
                            </PopOrg>
                            <PopOrg tw="italic">
                              {pi.role ? `${pi.role}` : ""}
                              {(edge.node.type == "lao" ||
                                edge.node.type == "dmacc") &&
                                `, ${edge.node.type.toUpperCase()}`}
                            </PopOrg>
                          </div>
                        ))}
                        {edge.node.url && (
                          <PopLink href={edge.node.url}>Website</PopLink>
                        )}
                      </Popup>
                    </Marker>
                  ))}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Division of Cancer Prevention">
              <LayerGroup>
                {laos.allLaosJson.edges
                  .filter(edge => edge.node.type == "sponsor")
                  .map(edge => (
                    <Marker
                      icon={
                        new L.Icon({
                          iconUrl: formatUrl("/marker-icon-red.png"),
                          shadowUrl: formatUrl("/marker-shadow.png"),
                          iconSize: [25, 41],
                          iconAnchor: [12, 41],
                          popupAnchor: [1, -34],
                          shadowSize: [41, 41]
                        })
                      }
                      key={edge.node.id}
                      position={[
                        edge.node.coordinates.lat,
                        edge.node.coordinates.long
                      ]}
                    >
                      <Popup>
                        {laos[edge.node.id] && (
                          <Img
                            fixed={laos[edge.node.id]?.childImageSharp?.fixed}
                          />
                        )}
                        {logos[edge.node.id] && (
                          <img
                            src={logos[edge.node.id]}
                            alt={`${edge.node.name} logo`}
                          />
                        )}
                        <PopName>{edge.node.name}</PopName>
                        {edge.node.pi?.map(pi => (
                          <div key={pi.name} tw="my-2">
                            <PopOrg>
                              {pi.name},{pi.degree && ` ${pi.degree},`}
                            </PopOrg>
                            <PopOrg tw="italic">
                              {pi.role ? `${pi.role}` : ""}
                              {(edge.node.type == "lao" ||
                                edge.node.type == "dmacc") &&
                                `, ${edge.node.type.toUpperCase()}`}
                            </PopOrg>
                          </div>
                        ))}
                        {/* CLUDGE: Sue wanted it this way; assumes everyone has same role */}
                        {edge.node.url && (
                          <PopLink href={edge.node.url}>Website</PopLink>
                        )}
                      </Popup>
                    </Marker>
                  ))}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay
              checked
              name="Lead Academic Organizations (LAOs)"
            >
              <LayerGroup>
                {laos.allLaosJson.edges
                  .filter(edge => edge.node.type == "lao")
                  .map(edge => (
                    <Marker
                      icon={
                        new L.Icon({
                          iconUrl: formatUrl("/marker-icon-violet.png"),
                          shadowUrl: formatUrl("/marker-shadow.png"),
                          iconSize: [25, 41],
                          iconAnchor: [12, 41],
                          popupAnchor: [1, -34],
                          shadowSize: [41, 41]
                        })
                      }
                      key={edge.node.id}
                      position={[
                        edge.node.coordinates.lat,
                        edge.node.coordinates.long
                      ]}
                    >
                      <Popup>
                        {edge.node.image && (
                          <Img
                            fixed={edge.node.image?.childImageSharp?.fixed}
                          />
                        )}
                        {logos[edge.node.id] && (
                          <img
                            src={logos[edge.node.id]}
                            alt={`${edge.node.name} logo`}
                          />
                        )}
                        <PopName>{edge.node.name}</PopName>
                        {edge.node.pi?.map(pi => (
                          <div key={pi.name} tw="my-2">
                            <PopOrg>
                              {pi.name},{pi.degree && ` ${pi.degree},`}
                            </PopOrg>
                            <PopOrg tw="italic">
                              {pi.role ? `${pi.role}` : ""}
                              {(edge.node.type == "lao" ||
                                edge.node.type == "dmacc") &&
                                `, ${edge.node.type.toUpperCase()}`}
                            </PopOrg>
                          </div>
                        ))}
                        {edge.node.url && (
                          <PopLink href={edge.node.url} target="_blank">
                            Website
                          </PopLink>
                        )}
                      </Popup>
                    </Marker>
                  ))}
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Sites (AOs)">
              <LayerGroup>
                <MarkerClusterGroup
                  showCoverageOnHover={false}
                  iconCreateFunction={createClusterAoIcon}
                >
                {laos.allAosJson.edges
                  .filter(edge => !edge.node.proposed)
                  .map(edge => (
                    <Marker
                      icon={
                        new L.Icon({
                          iconUrl: formatUrl("/marker-icon-black.png"),
                          shadowUrl: formatUrl("/marker-shadow.png"),
                          iconSize: [25, 41],
                          iconAnchor: [12, 41],
                          popupAnchor: [1, -34],
                          shadowSize: [41, 41]
                        })
                      }
                      key={edge.node.id}
                      position={[
                        edge.node.coordinates.lat,
                        edge.node.coordinates.long
                      ]}
                    >
                      <Popup>
                        <PopName>{edge.node.name}</PopName>
                      </Popup>
                    </Marker>
                  ))}
                </MarkerClusterGroup>
              </LayerGroup>
            </LayersControl.Overlay>
            <LayersControl.Overlay checked name="Affiliated Sites (AOs)">
              <LayerGroup>
                <MarkerClusterGroup
                  showCoverageOnHover={false}
                  iconCreateFunction={createClusterProposedIcon}
                >
                  {laos.allAosJson.edges
                    .filter(edge => edge.node.proposed)
                    .map(edge => (
                      <Marker
                        icon={
                          new L.Icon({
                            iconUrl: formatUrl("/marker-icon-grey.png"),
                            shadowUrl: formatUrl("/marker-shadow.png"),
                            iconSize: [25, 41],
                            iconAnchor: [12, 41],
                            popupAnchor: [1, -34],
                            shadowSize: [41, 41]
                          })
                        }
                        key={edge.node.id}
                        radius={5}
                        position={[
                          edge.node.coordinates.lat,
                          edge.node.coordinates.long
                        ]}
                      >
                        <Popup>
                          <PopName>
                            {edge.node.name}<br/>{edge.node.city}, {edge.node.country}
                          </PopName>
                        </Popup>
                      </Marker>
                    ))}
                </MarkerClusterGroup>
              </LayerGroup>
            </LayersControl.Overlay>
          </LayersControl>
        </MapContainer>
      )}
      <ul tw="flex flex-col md:flex-row flex-wrap md:space-x-4 text-xs text-gray-700 relative p-2">
        <li>
          <span style={{ color: "#2AAD27" }}>&#11044;</span> DMACC
        </li>
        <li>
          <span style={{ color: "#CB2B3E" }}>&#11044;</span> DCP
        </li>
        <li>
          <span style={{ color: "#9C2BCB" }}>&#11044;</span> Lead Academic
          Organizations (LAOs)
        </li>
        <li>
          <span style={{ color: "#3D3D3D" }}>&#11044;</span> Affiliated
          Organizations (AOs) - Active
        </li>
        <li>
          <span style={{ color: "#7B7B7B" }}>&#11044;</span> Affiliated
          Organizations (AOs)
        </li>
      </ul>
    </div>
  );
};

export default Map;

import React from "react";
import tw from "twin.macro";
import { useStaticQuery, graphql } from "gatsby";
import GatsbyImg from "gatsby-image";
import { SubHeading, Paragraph } from "../section";

const Container = tw.div`p-6 md:p-0 my-6`;
const Image = tw.div`rounded mb-4 h-48 bg-gray-100`;
const Img = tw(GatsbyImg)`object-cover h-48 w-full rounded`;

const AboutDmacc = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "dmacc-img.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 632) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Container>
      <Image>
        <Img fluid={data.file.childImageSharp.fluid} />
      </Image>
      <SubHeading>About DMACC</SubHeading>
      <Paragraph>
        The Data Management, Auditing and Coordinating Center supports the
        CP-CTNet sites and coordinates trans-network activities for centralized
        data management and reporting, clinical trials auditing, and
        administrative and logistical coordination across CP-CTNet sites. In
        addition, the DMACC collaborates with and advises the CP-CTNet sites
        with respect to trial design and protocol development.
      </Paragraph>
      <Paragraph>
        The DMACC is led by KyungMann Kim, PhD, of the University of Wisconsin
        (as Principal Investigator) and Sue Siminski, MS, MBA, of Frontier
        Science Foundation (as sub-Principal Investigator).
      </Paragraph>
    </Container>
  );
};

export default AboutDmacc;

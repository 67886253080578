import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import tw from "twin.macro";
import ReactCarousel from "@brainhubeu/react-carousel";
import BackgroundImage from "gatsby-background-image";
import "@brainhubeu/react-carousel/lib/style.css";
import "./carousel.css";

const CarouselContainer = tw.div`h-40 md:h-48 xl:h-64 bg-blue-900 flex`;
const CarouselLeadingBase = tw.div`absolute z-40 text-white max-w-xl leading-tight ml-4 mr-4 mt-4 flex items-start md:justify-center flex-col`;
const CarouselTitle = tw.h1`tracking-wide font-bold text-2xl md:text-4xl xl:text-6xl`;
const CarouselText = tw.div`max-w-full md:text-left tracking-wider font-bold text-base md:text-xl xl:text-2xl`;
const CarouselImage = tw(BackgroundImage)`block flex-1 w-full`;
const Slide = tw.div`shadow-inner flex-1 z-40 flex bg-blue-100`;
const SlideTextBase = tw(
  CarouselTitle
)`absolute z-40 text-white bottom-0 right-0 mx-4 mb-4 flex flex-1 items-end leading-none text-lg md:text-2xl lg:text-3xl`;

const SlideText = ({ children }) => (
  <SlideTextBase style={{ textShadow: "0 0 0.25rem #000000" }}>
    {children}
  </SlideTextBase>
);
SlideText.propTypes = { children: PropTypes.node };

const CarouselLeading = ({ children }) => (
  <CarouselLeadingBase style={{ textShadow: "0 0 1rem #000000" }}>
    {children}
  </CarouselLeadingBase>
);
CarouselLeading.propTypes = { children: PropTypes.node };

const Carousel = () => {
  const data = useStaticQuery(graphql`
    query {
      start: file(relativePath: { eq: "carousel-start.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      dataManagement: file(relativePath: { eq: "carousel-data.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      auditing: file(relativePath: { eq: "carousel-auditing.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      admin: file(relativePath: { eq: "carousel-admin.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <CarouselContainer>
      <CarouselLeading>
        <CarouselTitle>DMACC</CarouselTitle>
        <CarouselText>
          The Data Management, Auditing, and Coordinating Center for the Cancer
          Prevention Clinical Trials Network (CP&#8209;CTNet)
        </CarouselText>
      </CarouselLeading>
      <ReactCarousel infinite autoPlay={6000}>
        <Slide>
          <CarouselImage fluid={data.start.childImageSharp.fluid} Tag="div" />
        </Slide>
        <Slide>
          <SlideText>Data Management</SlideText>
          <CarouselImage
            fluid={data.dataManagement.childImageSharp.fluid}
            Tag="div"
          />
        </Slide>
        <Slide>
          <SlideText>Clinical Trial Auditing</SlideText>
          <CarouselImage
            fluid={data.auditing.childImageSharp.fluid}
            Tag="div"
          />
        </Slide>
        <Slide>
          <SlideText>Administration and Coordination</SlideText>
          <CarouselImage fluid={data.admin.childImageSharp.fluid} Tag="div" />
        </Slide>
      </ReactCarousel>
    </CarouselContainer>
  );
};

export default Carousel;

import React from "react";
import HomeLayout from "../components/layout";
import SEO from "../components/seo";
import HomepageContent from "../components/home";

const IndexPage = () => (
  <HomeLayout>
    <SEO title="Home" />
    <HomepageContent />
  </HomeLayout>
);

export default IndexPage;

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import tw from "twin.macro";
import { Heading } from "../section";

const Container = tw.div`container px-0`;
const LaoCard = tw.a`bg-white rounded hover:shadow-lg hover:opacity-100 opacity-75 focus:shadow-lg transition ease-in-out duration-300 flex flex-col`;
const LaoCardText = tw.div`tracking-wide text-white p-4 font-bold text-lg md:text-base lg:text-lg flex flex-1 text-center items-center justify-center rounded-b`;
const CardGrid = tw.div`grid md:grid-cols-2 xl:grid-cols-3 gap-8 pb-16`;
const CardLogo = tw(Img)`bg-white w-full h-40 mx-auto `;
const CardLogoContainer = tw.div`h-40 flex items-center`;

const Card = ({ name, bgcolor, imgFluid, ...rest }) => (
  <LaoCard {...rest}>
    <CardLogoContainer>
      {imgFluid && <CardLogo fluid={imgFluid} />}
    </CardLogoContainer>
    <LaoCardText
      style={{
        backgroundColor: bgcolor,
        minHeight: "10rem"
      }}
    >
      {name}
    </LaoCardText>
  </LaoCard>
);

Card.propTypes = {
  name: PropTypes.string,
  bgcolor: PropTypes.string,
  imgFluid: PropTypes.object
};

const Laos = () => {
  const data = useStaticQuery(graphql`
    query {
      laos: allLaosJson(
        sort: { fields: [sort], order: ASC }
        filter: { type: { eq: "lao" } }
      ) {
        edges {
          node {
            id
            sort
            org
            name
            bgcolor
            url
            image {
              childImageSharp {
                fluid(maxHeight: 100) {
                  ...GatsbyImageSharpFluid
                  ...GatsbyImageSharpFluidLimitPresentationSize
                }
              }
            }
          }
        }
      }
    }
  `);
  return (
    <Container>
      <Heading>Lead Academic Organizations</Heading>
      <CardGrid>
        {data.laos.edges.map(edge => {
          const {
            url,
            id,
            name,
            org,
            bgcolor,
            image: {
              childImageSharp: { fluid }
            }
          } = edge.node;
          return (
            <Card
              href={url}
              imgFluid={fluid}
              key={id}
              name={`${org}, ${name}`}
              bgcolor={bgcolor}
            ></Card>
          );
        })}
      </CardGrid>
    </Container>
  );
};

export default Laos;

import React from "react";
import tw from "twin.macro";
import { useStaticQuery, graphql } from "gatsby";
import GatsbyImg from "gatsby-image";
import { SubHeading, Paragraph, Hyperlink } from "../section";

const Container = tw.div`p-6 md:p-0 my-6`;
const Image = tw.div`rounded mb-4 h-48 bg-gray-100`;
const Img = tw(GatsbyImg)`object-cover h-48 w-full rounded`;

const AboutCpctnet = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "cp-ctnet-img.jpeg" }) {
        childImageSharp {
          fluid(maxWidth: 632) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <Container>
      <Image>
        <Img
          fluid={data.file.childImageSharp.fluid}
          imgStyle={{ objectFit: "cover" }}
        />
      </Image>
      <SubHeading>About CP-CTNet</SubHeading>
      <Paragraph>
        The Cancer Prevention Clinical Trials Network (CP-CTNet) is a major
        program of the National Cancer Institute (NCI){" "}
        <Hyperlink href="https://prevention.cancer.gov/major-programs/cancer-prevention-clinical-trials-network">
          Division of Cancer Prevention (DCP)
        </Hyperlink>
        . The purpose of CP-CTNet is to perform and provide clinical trial
        support for the efficient conduct of early-phase clinical trials,
        evaluate the biologic effects of preventive agents and interventions,
        and determine clinically relevant correlates in order to advance their
        development for cancer prevention.
      </Paragraph>
      <Paragraph>
        The key components of CP-CTNet are the CP-CTNet sites and the CP-CTNet
        Data Management, Auditing, and Coordinating Center (DMACC). Each
        CP-CTNet site consists of a Lead Academic Organization (LAO) and
        Affiliated Organizations (AOs) that work together to perform cancer
        prevention clinical trials.
      </Paragraph>
    </Container>
  );
};

export default AboutCpctnet;

import React from "react";
import tw from "twin.macro";
import { Heading, Container, SubHeading } from "../section";

const dmaccUnits = [
  {
    title: "Data Management and Reporting",
    tasks: [
      "Centralized data collection system via Medidata Rave®",
      "24/7 user support",
      "Written data management quality procedures",
      "Site training, reporting of data and metrics",
    ],
  },
  {
    title: "Clinical Trials Auditing",
    tasks: [
      "Independent auditing of trials and processes",
      "Guidance to ensure GCP, regulatory, and NCI guidelines are followed",
      "Collaboration with Site staff to identify systemic improvements",
    ],
  },
  {
    title: "Administration and Coordination",
    tasks: [
      "Unified operational structure",
      "Procedural documentation",
      "Statistical support",
      "Biospecimen inventory",
    ],
  },
];

const Card = tw.div`mx-3 md:mb-4 p-4 z-50`;
const Grid = tw.div`md:grid grid-cols-3`;
const TaskList = tw.ul`list-disc`;
const TaskListItem = tw.li``;

const WhatDmaccDoes = () => (
  <Container>
    <Heading>What the DMACC Does</Heading>
    <Grid>
      {dmaccUnits.map((unit, i) => (
        <Card key={`unit-${i}`}>
          <SubHeading>{unit.title}</SubHeading>
          <TaskList>
            {unit.tasks.map((task, k) => (
              <TaskListItem key={`task-${k}`}>{task}</TaskListItem>
            ))}
          </TaskList>
        </Card>
      ))}
    </Grid>
  </Container>
);

export default WhatDmaccDoes;
